(function(i, p, w, d, b, t) {

    var cube_ad = {
        styles: function() {
            var html = '<style>' +
                this.id + ' { margin:0 auto; width:300px; height:250px; background:transparent; perspective:600px; perspective-origin:50% 50%; position:relative; }' +
                this.id + ' .cube-ad { width:300px; height:250px; transform-style:preserve-3d; }' +
                this.id + ' .cube-ad div { position:absolute; width:300px; height:250px; opacity:1; }' +
                this.id + ' .cube-ad .front { transform:rotateY(0deg) translateZ(150px); }' +
                this.id + ' .cube-ad .right { transform:rotateY(90deg) translateZ(150px); }' +
                this.id + ' .cube-ad .back { transform:rotateY(180deg) translateZ(150px); }' +
                this.id + ' .cube-ad .left { transform:rotateY(-90deg) translateZ(150px); }' +
                this.id + ' .cube-nav-wrapper { position:absolute; top:50%; left:0; display:flex; justify-content:space-between; width:100%; height:1px; }' +
                this.id + ' .cube-nav { color:#222; background-color:#fff; width:32px; height:32px; text-align:center; padding-top:7px; margin-top:-16px; cursor:pointer; opacity:0.4; }' +
                this.id + ' .cube-nav:hover { opacity:1; }' +
                this.id + ' .cube-nav svg { pointer-events:none; }' +
            '</style>';
            return html;
        },

        html: function() {
            var html = this.styles();
            html += '' +
                '<div id="'+ this.id.replace('#','') +'">' +
                    '<div class="cube-ad" style="transform:translateZ(-150px) rotateY(0deg);">' +
                        '<div class="front">' +
                            '<a href="'+ this.settings.click_tag +'" target="_blank">' +
                                '<img src="'+ this.settings.image_front +'" alt="">' +
                            '</a>' +
                        '</div>' +
                        '<div class="right">' +
                            '<a href="'+ this.settings.click_tag +'" target="_blank">' +
                                '<img src="'+ this.settings.image_right +'" alt="">' +
                            '</a>' +
                        '</div>' +
                        '<div class="back">' +
                            '<a href="'+ this.settings.click_tag +'" target="_blank">' +
                                '<img src="'+ this.settings.image_back +'" alt="">' +
                            '</a>' +
                        '</div>' +
                        '<div class="left">' +
                            '<a href="'+ this.settings.click_tag +'" target="_blank">' +
                                '<img src="'+ this.settings.image_left +'" alt="">' +
                            '</a>' +
                        '</div>' +
                    '</div>';

                    if (this.settings.show_nav) {
                        html += '' +
                            '<div class="cube-nav-wrapper">' +
                                '<a class="cube-nav prev">' +
                                    '<i class="fas tnt-chevron-left"></i>' +
                                    '<span class="sr-only">Previous</span>' +
                                '</a>' +
                                '<a class="cube-nav next">' +
                                    '<i class="fas tnt-chevron-right"></i>' +
                                    '<span class="sr-only">Next</span>' +
                                '</a>' +
                            '</div>';
                    }

                html += '</div>';

            if (this.settings.tracking_pixel) {
                html += '<div style="display:none;"><img src="' + this.settings.tracking_pixel + '" height="1" width="1"></div>';
            }

            return html;
        },

        init: function() {
            var cube_wrapper = d.getElementById(this.id.replace('#',''));
            var cube = cube_wrapper.querySelector('.cube-ad');
            var rotate_y = 0;
            var is_animating = false;
            var is_click = false;
            var auto_spin_interval = this.settings.auto_spin_interval;
            var auto_spin_timer;

            // Drag vars
            var posX1 = 0,
                posX2 = 0,
                posInitial,
                posFinal,
                threshold = 12,
                delta = 6,
                start_x,
                start_y;

            // Fix JS negative modulo bug
            function mod(n, m) {
                return ((n % m) + m) % m;
            }

            // Get correct translateZ value
            function getZ(ry) {
                var progress = mod(ry, 90) / 90;
                return 400 * (progress * progress) - 400 * progress - 150;
            }

            // Mouse and touch events
            cube_wrapper.onmousedown = dragStart;

            // Touch events
            cube_wrapper.addEventListener('touchstart', dragStart);
            cube_wrapper.addEventListener('touchend', dragEnd);
            cube_wrapper.addEventListener('touchmove', dragAction);

            function dragStart(e) {
                e = e || w.event;
                e.preventDefault();
                posInitial = rotate_y;

                if (e.type == 'touchstart') {
                    posX1 = e.touches[0].clientX;
                } else {
                    posX1 = e.clientX;
                    d.onmouseup = dragEnd;
                    d.onmousemove = dragAction;
                }
                start_x = posX1;
            }

            function dragAction(e) {
                e = e || w.event;

                if (e.type == 'touchmove') {
                    posX2 = posX1 - e.touches[0].clientX;
                    posX1 = e.touches[0].clientX;
                } else {
                    posX2 = posX1 - e.clientX;
                    posX1 = e.clientX;
                }
                rotate_y = (rotate_y - (posX2/2)) % 360;

                cube.style.transform = 'translateZ('+ getZ(rotate_y) +'px) rotateY('+ (rotate_y) +'deg)';
            }

            function dragEnd(e) {
                var end_x = posX1;

                if (Math.abs(end_x - start_x) < delta || e.target.classList.contains('cube-nav')) {
                    is_click = true;
                    handleClick(e);
                } else {
                    is_click = false;
                }

                if (!is_click) {
                    posFinal = rotate_y;

                    if (posFinal - posInitial < 0) {
                        rotateCube(-1);
                    } else if (posFinal - posInitial > 0) {
                        rotateCube(1);
                    }
                }

                d.onmouseup = null;
                d.onmousemove = null;
            }

            function autoSpin() {
                rotateCube(cube_ad.settings.auto_spin_direction);
                auto_spin_timer = w.setTimeout(autoSpin, auto_spin_interval);
            }

            if (auto_spin_interval > 0) {
                auto_spin_timer = w.setTimeout(autoSpin, auto_spin_interval);

                cube_wrapper.addEventListener('mouseenter', function(e) {
                    w.clearTimeout(auto_spin_timer);
                });

                cube_wrapper.addEventListener('mouseleave', function(e) {
                    auto_spin_timer = w.setTimeout(autoSpin, auto_spin_interval);
                });
            }

            function animate({timing, draw, duration, callback}) {
                var start = performance.now();

                requestAnimationFrame(function animate(time) {
                    // timeFraction goes from 0 to 1
                    var timeFraction = (time - start) / duration;
                    if (timeFraction > 1) timeFraction = 1;

                    // calculate the current animation state
                    var progress = timing(timeFraction);

                    draw(progress);

                    if (timeFraction < 1) {
                        requestAnimationFrame(animate);
                    }

                    if (timeFraction === 1) {
                        callback();
                    }
                });
            }

            function rotateCube(dir) {
                if (typeof dir === undefined || (dir !== -1 && dir !== 1)) {
                    dir = 1;
                }

                var rotate_start = rotate_y,
                    rotate_amount,
                    max_rotate_duration = cube_ad.settings.speed,
                    rotate_duration,
                    is_animating = true;

                // partial rotate
                if (rotate_y % 90 !== 0) {
                    if (dir == 1) {
                        rotate_amount = Math.abs((Math.ceil(rotate_y/90) * 90) - rotate_y);
                    } else {
                        rotate_amount = Math.abs((Math.floor(rotate_y/90) * 90) - rotate_y);
                    }
                    rotate_duration = (Math.abs(rotate_amount) / 90) * max_rotate_duration;

                // full rotate
                } else {
                    rotate_duration = max_rotate_duration;
                    rotate_amount = 90;
                }

                animate({
                    duration: rotate_duration,
                    timing: function(x) {
                        return -(Math.cos(Math.PI * x) - 1) / 2;
                    },
                    draw: function(progress) {
                        rotate_y = rotate_start + ((progress * rotate_amount) * dir);
                        var progress2 = mod(rotate_y, (90*dir)) / (90*dir);
                        var z = 400 * (progress2 * progress2) - 400 * progress2 - 150;
                        cube.style.transform = 'translateZ('+ z +'px) rotateY('+ (rotate_y) +'deg)';
                    },
                    callback: function() {
                        if (rotate_y % 360 === 0) {
                            rotate_y = 0;
                            cube.style.transform = 'translateZ(-150px) rotateY(0deg)';
                        }
                        is_animating = false;
                    }
                });
            }

            function handleClick(e) {
                if (e.target.classList.contains('cube-nav')) {
                    if (e.target.classList.contains('prev')) {
                        if (!is_animating) {
                            rotateCube(-1);
                        }
                    } else if (e.target.classList.contains('next')) {
                        if (!is_animating) {
                            rotateCube(1);
                        }
                    }
                } else if (e.target.tagName == 'A' || e.target.parentNode.tagName == 'A') {
                    var target = (e.target.tagName == 'A') ? e.target : e.target.parentNode;
                    e.preventDefault();
                    if (is_click) {
                        w.open(target.href, 1);
                    }
                }
            }

            cube.addEventListener('click', handleClick);

            if (cube_ad.settings.show_nav) {
                cube_wrapper.querySelector('.cube-nav-wrapper').addEventListener('click', handleClick);
            }

        },

        load: function(settings) {
            this.settings = settings;

            this.id = '#cube-ad-wrapper-'+ this.settings.line_item_id +'-'+ this.settings.creative_id;
            this.settings.speed = parseInt(this.settings.speed);
            this.settings.auto_spin_interval = parseInt(this.settings.auto_spin_interval);
            this.settings.auto_spin_direction = (settings.auto_spin_direction === 'Right') ? 1 : -1;
            this.settings.show_nav = (settings.show_nav === 'True') ? true : false;

            p.parentNode.insertAdjacentHTML('afterend', this.html(this.settings));
            p.width = 1;
            p.height = 1;

            this.init();
        }
    };

    try {
        if (!i) throw 'Friendly iframe required.';

        t.cmds.forEach(function(item) {
            cube_ad.load(item.call());
            return false;
        });
    } catch(e) {
        if (w.console) w.console.warn(e);
    }

})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.__TNT_AD || {})
);